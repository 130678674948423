import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ClassesMixin extends Vue {
  get editMode() {
    return this.$store.state.classes.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit('classes/setEditMode', value);
  }

  get fullEditorEditMode() {
    return this.$store.state.classes.fullEditorEditMode;
  }

  set fullEditorEditMode(v: boolean) {
    this.$store.commit('classes/setFullEditorEditMode', v);
  }

  get scheduleName() {
    return this.$store.state.classes.scheduleName;
  }

  set scheduleName(v: string) {
    this.$store.commit('classes/setScheduleName', v);
  }

  get isRegularSchedule() {
    return this.$store.state.classes.isRegularSchedule;
  }

  set isRegularSchedule(v: boolean) {
    this.$store.commit('classes/setIsRegularSchedule', v);
  }

  get scheduleAsOfFC() {
    return this.$store.state.classes.scheduleAsOfFC;
  }

  set scheduleAsOfFC(v: string) {
    this.$store.commit('classes/setScheduleAsOfFC', v);
  }

  get isEditingFromClassStart() {
    return this.$store.state.classes.isEditingFromClassStart;
  }

  set isEditingFromClassStart(v: boolean) {
    this.$store.commit('classes/setIsEditingFromClassStart', v);
  }

  created() {
    this.editMode = false;
    this.fullEditorEditMode = false;
    this.scheduleAsOfFC = moment().format('MM/DD/YYYY');
    this.isEditingFromClassStart = false;
  }
}
