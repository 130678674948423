var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isModal
    ? _c(
        "pb-base-modal",
        {
          attrs: { withActions: false, expandable: false },
          on: { apply: _vm.doApply },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [
                    _c("div", [
                      _vm.action === "A"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("addAlternateDayLabel"))),
                          ])
                        : _vm.action === "D"
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$t("deleteAlternateDayLabel"))),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(_vm.$t("editAlternateDayLabel"))),
                          ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2118887074
          ),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _vm.action !== "D"
            ? _c(
                "div",
                [
                  _c("v-text-field", {
                    staticClass: "pa-3",
                    attrs: {
                      label: _vm.$t("enterAlternateTitleLabel"),
                      outlined: "",
                      clearable: "",
                      "hide-details": "auto",
                      rules: _vm.localRules,
                      "validate-on-blur": "",
                    },
                    model: {
                      value: _vm.localAlternateDayTitle,
                      callback: function ($$v) {
                        _vm.localAlternateDayTitle = $$v
                      },
                      expression: "localAlternateDayTitle",
                    },
                  }),
                  _c("pb-text-editor", {
                    staticClass: "pa-3",
                    attrs: {
                      refreshKey: _vm.localRefreshKey,
                      config: { height: "300px" },
                    },
                    model: {
                      value: _vm.eventText,
                      callback: function ($$v) {
                        _vm.eventText = $$v
                      },
                      expression: "eventText",
                    },
                  }),
                  _c("pb-confirm", { ref: "confirm" }),
                ],
                1
              )
            : _vm.action === "D"
            ? _c(
                "v-card",
                { staticClass: "ma-2", attrs: { outlined: "" } },
                [
                  _c("v-card-title", [
                    _vm._v(_vm._s(_vm.$t("deleteAlternateDayText"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }