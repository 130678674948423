var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    { staticClass: "classes", attrs: { color: "#ffffff" } },
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-row",
            { staticClass: "left-list-right-editor-box my-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "left-list-box ml-1 py-4 pa-0",
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndDown ? 4 : 3 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left-list-items mt-5",
                      attrs: { id: "externalEventsClasses" },
                    },
                    [
                      _c(
                        "perfect-scrollbar",
                        {
                          style: {
                            height: _vm.hasBanner
                              ? "calc(100vh - 200px)"
                              : "calc(100vh - 120px)",
                          },
                        },
                        [
                          _vm._l(_vm.externalClasses, function (item, i) {
                            return _c("v-hover", {
                              key: _vm.localRefreshKey + i,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ hover }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            class: _vm.editMode
                                              ? "draggable-el grabbable"
                                              : "",
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "left-list-item-card ml-7 mr-5 my-3",
                                                style:
                                                  hover ||
                                                  _vm.focusItem === item.cId
                                                    ? { borderColor: item.cC }
                                                    : {},
                                                attrs: {
                                                  elevation:
                                                    hover ||
                                                    _vm.focusItem === item.cId
                                                      ? 12
                                                      : 5,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [_vm._v(_vm._s(item.cN))]
                                                    ),
                                                    _c(
                                                      "rostered-item-indicator",
                                                      {
                                                        key:
                                                          item.cId +
                                                          item.isItemRostered,
                                                        attrs: {
                                                          itemType: "subject",
                                                          item: item,
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        !_vm.editMode
                                                          ? _c(
                                                              "v-row",
                                                              {
                                                                style: {
                                                                  "max-height":
                                                                    hover ||
                                                                    _vm.focusItem ===
                                                                      item.cId
                                                                      ? ""
                                                                      : "0px",
                                                                },
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                  justify:
                                                                    "end",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "class-actions",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        hover ||
                                                                        _vm.focusItem ===
                                                                          item.cId
                                                                          ? ""
                                                                          : "transparent",
                                                                      type: "button",
                                                                      input: {
                                                                        data: {
                                                                          action:
                                                                            "E",
                                                                          isModal: false,
                                                                          className:
                                                                            "",
                                                                          color:
                                                                            "",
                                                                        },
                                                                        isNewClass: false,
                                                                        selectedClass:
                                                                          _vm.selectedClass,
                                                                      },
                                                                    },
                                                                    on: {
                                                                      focus:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.focus(
                                                                            item
                                                                          )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        _vm.focusItem =
                                                                          ""
                                                                      },
                                                                    },
                                                                  }
                                                                ),
                                                                !_vm.mCurrentDate.isAfter(
                                                                  _vm.mCurrentYearLastDay
                                                                )
                                                                  ? _c(
                                                                      "pb-btn",
                                                                      {
                                                                        staticClass:
                                                                          "mx-2 ml-0",
                                                                        attrs: {
                                                                          color:
                                                                            hover ||
                                                                            _vm.focusItem ===
                                                                              item.cId
                                                                              ? "primary"
                                                                              : "transparent",
                                                                          text: "",
                                                                          small:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.editClass(
                                                                                item.cId
                                                                              )
                                                                            },
                                                                          focus:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.focusItem =
                                                                                item.cId
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            _vm.focusItem =
                                                                              ""
                                                                          },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "editLabel"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        !(
                                                          (hover ||
                                                            _vm.focusItem ===
                                                              item.cId) &&
                                                          !_vm.editMode
                                                        )
                                                          ? _c(
                                                              "v-row",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                  justify:
                                                                    "end",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mr-3",
                                                                    style: {
                                                                      color:
                                                                        item.cC,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-palette",
                                                                      class:
                                                                        _vm.isColorReadable(
                                                                          item.cC
                                                                        )
                                                                          ? ""
                                                                          : "pb-bordered-icon",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-card-subtitle", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "subtitle-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getDisplayDate(
                                                            item.cSd
                                                          )
                                                        ) + " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-arrow-right",
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getDisplayDate(
                                                              item.cEd
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "none",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.cId))]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        display: "none",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(item.cC))]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          }),
                          _vm.localNewClass
                            ? _c("v-hover", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ hover }) {
                                        return [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "left-list-item-card ml-7 mr-5 my-3",
                                                  attrs: {
                                                    elevation: hover ? 12 : 5,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    [
                                                      _c(
                                                        "v-form",
                                                        {
                                                          ref: "classEditorForm",
                                                          attrs: {
                                                            "lazy-validation":
                                                              "",
                                                          },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "classNameLabel"
                                                                ) + "*",
                                                              "aria-required":
                                                                "true",
                                                              "hide-details":
                                                                "auto",
                                                              rules:
                                                                _vm.localRules,
                                                              "validate-on-blur":
                                                                "",
                                                              autofocus: "",
                                                              clearable: "",
                                                              dense: "",
                                                            },
                                                            on: {
                                                              keydown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  $event.preventDefault()
                                                                  return _vm.applyQuicklyAddedClass.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .localNewClass
                                                                  .className,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.localNewClass,
                                                                    "className",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "localNewClass.className",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      false
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                align: "center",
                                                                justify: "end",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "pb-color-picker",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    icon: "fas fa-palette",
                                                                    origin:
                                                                      "auto",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .localNewClass
                                                                        .color,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.localNewClass,
                                                                          "color",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "localNewClass.color",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    transition:
                                                                      "scroll-y-transition",
                                                                    "offset-y":
                                                                      "",
                                                                    "close-on-content-click":
                                                                      "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-3 mt-1",
                                                                                      attrs:
                                                                                        {
                                                                                          icon: "",
                                                                                          right:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fal fa-ellipsis-v-alt"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    [
                                                                      _c(
                                                                        "v-list",
                                                                        [
                                                                          _c(
                                                                            "v-list-item",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "pb-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.removeQuicklyAddedClass.apply(
                                                                                              null,
                                                                                              arguments
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "cancelLabel"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-list-item",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "pb-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.addClassAdvancedMode.apply(
                                                                                              null,
                                                                                              arguments
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "moreOptionsLabel"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-list-item",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "pb-btn",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.applyQuicklyAddedClass.apply(
                                                                                              null,
                                                                                              arguments
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "applyLabel"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : (_vm.$vuetify
                                                            .breakpoint.xl ||
                                                            _vm.$vuetify
                                                              .breakpoint
                                                              .lgAndDown) &&
                                                          !_vm.$vuetify
                                                            .breakpoint
                                                            .mdAndDown
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "pt-2 pr-2",
                                                              attrs: {
                                                                align: "center",
                                                                justify: "end",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "40px",
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "error",
                                                                    label:
                                                                      _vm.$t(
                                                                        "cancelLabel"
                                                                      ),
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.removeQuicklyAddedClass.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fal fa-xmark"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "pb-color-picker",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    icon: "fas fa-palette",
                                                                    origin:
                                                                      "auto",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .localNewClass
                                                                        .color,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.localNewClass,
                                                                          "color",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "localNewClass.color",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "40px",
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "accent1",
                                                                    label:
                                                                      _vm.$t(
                                                                        "moreOptionsLabel"
                                                                      ),
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.addClassAdvancedMode.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-gear"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "40px",
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "success",
                                                                    label:
                                                                      _vm.$t(
                                                                        "applyLabel"
                                                                      ),
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.applyQuicklyAddedClass.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-check"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-card-subtitle", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getDisplayDate(
                                                              _vm.localNewClass
                                                                .classStartDate
                                                            )
                                                          ) + " "
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "fal fa-arrow-right",
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getDisplayDate(
                                                                _vm
                                                                  .localNewClass
                                                                  .classEndDate
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _vm.$vuetify.breakpoint
                                                    .mdAndDown
                                                    ? _c(
                                                        "v-card-actions",
                                                        {
                                                          staticClass:
                                                            "mt-0 pt-0 pb-5",
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "pr-2",
                                                              attrs: {
                                                                align: "center",
                                                                justify: "end",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "40px",
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "error",
                                                                    label:
                                                                      _vm.$t(
                                                                        "cancelLabel"
                                                                      ),
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.removeQuicklyAddedClass.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fal fa-xmark"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "pb-color-picker",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    icon: "fas fa-palette",
                                                                    origin:
                                                                      "auto",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .localNewClass
                                                                        .color,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.localNewClass,
                                                                          "color",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "localNewClass.color",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "40px",
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "accent1",
                                                                    label:
                                                                      _vm.$t(
                                                                        "moreOptionsLabel"
                                                                      ),
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.addClassAdvancedMode.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-gear"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "pb-btn",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "40px",
                                                                    height:
                                                                      "40px",
                                                                    color:
                                                                      "success",
                                                                    label:
                                                                      _vm.$t(
                                                                        "applyLabel"
                                                                      ),
                                                                    icon: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.applyQuicklyAddedClass.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fas fa-check"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3847952584
                                ),
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-divider", {
                staticClass: "hidden-xs-only",
                attrs: { vertical: "" },
              }),
              _c(
                "v-col",
                {
                  staticClass: "right-editor-box mt-9 text-center",
                  class: _vm.fullCalendarClass,
                  attrs: { cols: _vm.$vuetify.breakpoint.lgAndDown ? 7 : 8 },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col"),
                      _vm.specialDayIdSelected
                        ? _c(
                            "v-col",
                            { staticClass: "d-flex pa-0" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn-toggle",
                                {
                                  attrs: {
                                    color: "primary",
                                    dense: "",
                                    mandatory: "",
                                  },
                                  model: {
                                    value: _vm.viewListSchedule,
                                    callback: function ($$v) {
                                      _vm.viewListSchedule = $$v
                                    },
                                    expression: "viewListSchedule",
                                  },
                                },
                                [
                                  _c("v-btn", [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("calendarLabel"))),
                                    ]),
                                  ]),
                                  _c("v-btn", [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("listLabel"))),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-spacer"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "end" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    bottom: "",
                                    "offset-y": "",
                                    "close-on-click": "",
                                    "close-on-content-click": false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: onMenu }) {
                                          return [
                                            _c(
                                              "pb-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "ml-2 my-1",
                                                  attrs: {
                                                    color: "primary",
                                                    label: "View Settings",
                                                    text: "",
                                                    outlined: "",
                                                  },
                                                },
                                                { ...onMenu }
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fal fa-gear"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4158551232
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c("v-select", {
                                            staticStyle: {
                                              "max-width": "180px",
                                            },
                                            attrs: {
                                              items: _vm.slotDurationOptions,
                                              label:
                                                _vm.$t("slotDurationLabel"),
                                              "return-object": "",
                                              "hide-details": "auto",
                                              "display:inline-flex": "",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.slotDuration,
                                              callback: function ($$v) {
                                                _vm.slotDuration = $$v
                                              },
                                              expression: "slotDuration",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    bottom: "",
                                    "offset-y": "",
                                    "close-on-click": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: onMenu }) {
                                          return [
                                            _c(
                                              "pb-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "ml-2 my-1 mr-3",
                                                  attrs: {
                                                    color: "primary",
                                                    label: _vm.$t(
                                                      "exportScheduleLabel"
                                                    ),
                                                    disabled:
                                                      !_vm.allowExportClasses,
                                                    text: "",
                                                    outlined: "",
                                                  },
                                                },
                                                { ...onMenu }
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "fal fa-arrow-up-from-square"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2983830922
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.exportSchedule("p")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-file-pdf")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("pdfLabel")) +
                                                      " (" +
                                                      _vm._s(
                                                        _vm.$t("portraitLabel")
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.exportSchedule("l")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-file-pdf")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("pdfLabel")) +
                                                      " (" +
                                                      _vm._s(
                                                        _vm.$t("landscapeLabel")
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: { click: _vm.openScheduleNewTab },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-browsers")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "ml-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("HTMLLabel"))
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.specialDayIdSelected && _vm.viewListSchedule
                    ? _c(
                        "v-card",
                        {
                          staticClass: "mt-8",
                          staticStyle: { "max-height": "calc(100vh - 160px)" },
                          attrs: { elevation: "1" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("div", { staticClass: "text-left ml-13" }, [
                                  _vm._v(_vm._s(_vm.$t("classLabel"))),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("div", { staticClass: "text-left" }, [
                                  _vm._v(_vm._s(_vm.$t("teachingLabel"))),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _vm._v(_vm._s(_vm.$t("timeRangeLabel"))),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pt-4 pb-4",
                              staticStyle: {
                                overflow: "auto",
                                "max-height": "calc(100vh - 220px)",
                              },
                            },
                            _vm._l(
                              _vm.alternateDayClassTimes,
                              function (item, i) {
                                return _c(
                                  "v-row",
                                  { key: i },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-left mt-4 pl-8",
                                        attrs: { cols: "4" },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-4",
                                            attrs: { color: item.classColor },
                                          },
                                          [
                                            _vm._v(
                                              "fa-solid fa-rectangle-vertical"
                                            ),
                                          ]
                                        ),
                                        _vm._v(_vm._s(item.className)),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "2" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            "hide-details": "auto",
                                            readonly: !_vm.editMode,
                                            "input-value": item.teach === "Y",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.updateAlternateScheduleTeach(
                                                item.subjectId
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pr-6",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c("pb-date-time-range-field", {
                                          attrs: {
                                            readonly: !_vm.editMode,
                                            type: "time",
                                            start: item.startTime,
                                            end: item.endTime,
                                            min: _vm.startTime24HrFmt,
                                            max: _vm.endTime24HrFmt,
                                            "hide-details": "auto",
                                            disabled: item.teach === "N",
                                          },
                                          on: {
                                            "update:start": function ($event) {
                                              return _vm.$set(
                                                item,
                                                "startTime",
                                                $event
                                              )
                                            },
                                            "update:end": function ($event) {
                                              return _vm.$set(
                                                item,
                                                "endTime",
                                                $event
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.updateAlternateScheduleTimes(
                                                item.subjectId
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _c("fullcalendar", {
                        ref: "fullCalendar",
                        staticClass: "fullcalendar-box",
                        style:
                          _vm.isPrinting === "pdf"
                            ? `min-width: ${_vm.printWidth}px; color: black !important`
                            : "",
                        attrs: {
                          id: "classesCalendar",
                          options: _vm.calendarOptions,
                          refreshKey: _vm.localRefreshKey,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "eventContent",
                              fn: function (arg) {
                                return [
                                  _vm.isOverflowing(arg)
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        arg.event.allDay
                                                          ? _c("div", {
                                                              staticClass:
                                                                "fc-event-title-container fc-event-title",
                                                            })
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fc-event-title-container fc-event-title",
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatTimeToShow(
                                                                        arg
                                                                          .event
                                                                          .start
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                arg.event.end
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        " - "
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                arg.event.end
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatTimeToShow(
                                                                            arg
                                                                              .event
                                                                              .end
                                                                          )
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(arg.event.title) + " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        { attrs: { id: "event-container" } },
                                        [
                                          arg.event.allDay
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fc-event-title-container fc-event-title",
                                                  staticStyle: {
                                                    "max-width": "100%",
                                                  },
                                                  attrs: {
                                                    id: "all-day-title",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(arg.event.title)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fc-event-title-container fc-event-title",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatTimeToShow(
                                                          arg.event.start
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  arg.event.end
                                                    ? _c("span", [
                                                        _vm._v(" - "),
                                                      ])
                                                    : _vm._e(),
                                                  arg.event.end
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatTimeToShow(
                                                              arg.event.end
                                                            )
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _vm.getEventDurationMinutes(
                                                    arg.event.start,
                                                    arg.event.end
                                                  ) >= 30
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              arg.event.title
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _c("span", [
                                                          _vm._v(" - "),
                                                        ]),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              arg.event.title
                                                            )
                                                          ),
                                                        ]),
                                                      ]),
                                                ]
                                              ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3824079272
                        ),
                      }),
                  _c(
                    "pb-sliding-sheet",
                    {
                      class: _vm.slidingSheetMarginTop,
                      attrs: {
                        elevation: "0",
                        height: "100%",
                        width: _vm.slidingSheetWidth,
                        value: _vm.localIsEditingClass,
                        showCloseButton: false,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.localIsEditingClass = $event
                        },
                      },
                    },
                    [
                      _c("pb-class-editor", {
                        ref: "classEditor",
                        attrs: {
                          isModal: false,
                          input: _vm.localInput,
                          value: _vm.localIsEditingClass,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "my-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "perfect-scrollbar",
                    [
                      _vm._l(_vm.externalClasses, function (item, i) {
                        return _c("v-hover", {
                          key: _vm.localRefreshKey + i,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ hover }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        class: _vm.editMode
                                          ? "draggable-el grabbable"
                                          : "",
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "left-list-item-card mx-5 my-3",
                                            style:
                                              hover ||
                                              _vm.focusItem === item.cId
                                                ? { borderColor: item.cC }
                                                : {},
                                            attrs: {
                                              elevation:
                                                hover ||
                                                _vm.focusItem === item.cId
                                                  ? 12
                                                  : 5,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "body-2" },
                                                  [_vm._v(_vm._s(item.cN))]
                                                ),
                                                _c("rostered-item-indicator", {
                                                  key:
                                                    item.cId +
                                                    item.isItemRostered,
                                                  attrs: {
                                                    itemType: "subject",
                                                    item: item,
                                                  },
                                                }),
                                                !_vm.editMode
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          align: "center",
                                                          justify: "end",
                                                        },
                                                      },
                                                      [
                                                        _c("class-actions", {
                                                          attrs: {
                                                            color:
                                                              hover ||
                                                              _vm.focusItem ===
                                                                item.cId
                                                                ? ""
                                                                : "transparent",
                                                            type: "button",
                                                            input: {
                                                              data: {
                                                                action: "E",
                                                                isModal: false,
                                                                className: "",
                                                                color: "",
                                                              },
                                                              isNewClass: false,
                                                              selectedClass:
                                                                _vm.selectedClass,
                                                            },
                                                          },
                                                          on: {
                                                            focus: function (
                                                              $event
                                                            ) {
                                                              return _vm.focus(
                                                                item
                                                              )
                                                            },
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              _vm.focusItem = ""
                                                            },
                                                          },
                                                        }),
                                                        !_vm.mCurrentDate.isAfter(
                                                          _vm.mCurrentYearLastDay
                                                        )
                                                          ? _c(
                                                              "pb-btn",
                                                              {
                                                                staticClass:
                                                                  "mx-2 ml-0",
                                                                attrs: {
                                                                  color:
                                                                    hover ||
                                                                    _vm.focusItem ===
                                                                      item.cId
                                                                      ? "primary"
                                                                      : "transparent",
                                                                  text: "",
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.editClass(
                                                                        item.cId
                                                                      )
                                                                    },
                                                                  focus:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.focusItem =
                                                                        item.cId
                                                                    },
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    _vm.focusItem =
                                                                      ""
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "editLabel"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !(
                                                  (hover ||
                                                    _vm.focusItem ===
                                                      item.cId) &&
                                                  !_vm.editMode
                                                )
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        attrs: {
                                                          align: "center",
                                                          justify: "end",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "mr-3",
                                                            style: {
                                                              color: item.cC,
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-palette",
                                                              class:
                                                                _vm.isColorReadable(
                                                                  item.cC
                                                                )
                                                                  ? ""
                                                                  : "pb-bordered-icon",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c("v-card-subtitle", [
                                              _c(
                                                "span",
                                                { staticClass: "subtitle-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDisplayDate(
                                                        item.cSd
                                                      )
                                                    ) + " "
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "fal fa-arrow-right",
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getDisplayDate(
                                                          item.cEd
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.cId))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.cC))]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _vm.localNewClass
                        ? _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "left-list-item-card ml-7 mr-5 my-3",
                                              attrs: {
                                                elevation: hover ? 12 : 5,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c(
                                                    "v-form",
                                                    {
                                                      ref: "classEditorForm",
                                                      attrs: {
                                                        "lazy-validation": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "classNameLabel"
                                                            ) + "*",
                                                          "aria-required":
                                                            "true",
                                                          "hide-details":
                                                            "auto",
                                                          rules: _vm.localRules,
                                                          "validate-on-blur":
                                                            "",
                                                          autofocus: "",
                                                          clearable: "",
                                                          dense: "",
                                                        },
                                                        on: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            $event.preventDefault()
                                                            return _vm.applyQuicklyAddedClass.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.localNewClass
                                                              .className,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.localNewClass,
                                                              "className",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "localNewClass.className",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "pt-2 pr-2",
                                                      attrs: {
                                                        align: "center",
                                                        justify: "end",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "pb-btn",
                                                        {
                                                          attrs: {
                                                            width: "40px",
                                                            height: "40px",
                                                            color: "error",
                                                            label:
                                                              _vm.$t(
                                                                "cancelLabel"
                                                              ),
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.removeQuicklyAddedClass.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-xmark"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("pb-color-picker", {
                                                        attrs: {
                                                          small: "",
                                                          icon: "fas fa-palette",
                                                          origin: "auto",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.localNewClass
                                                              .color,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.localNewClass,
                                                              "color",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "localNewClass.color",
                                                        },
                                                      }),
                                                      _c(
                                                        "pb-btn",
                                                        {
                                                          attrs: {
                                                            width: "40px",
                                                            height: "40px",
                                                            color: "accent1",
                                                            label:
                                                              _vm.$t(
                                                                "moreOptionsLabel"
                                                              ),
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.addClassAdvancedMode.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-gear"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "pb-btn",
                                                        {
                                                          attrs: {
                                                            width: "40px",
                                                            height: "40px",
                                                            color: "success",
                                                            label:
                                                              _vm.$t(
                                                                "applyLabel"
                                                              ),
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.applyQuicklyAddedClass.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fas fa-check"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-card-subtitle", [
                                                _c(
                                                  "span",
                                                  { staticClass: "subtitle-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getDisplayDate(
                                                          _vm.localNewClass
                                                            .classStartDate
                                                        )
                                                      ) + " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-arrow-right",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getDisplayDate(
                                                            _vm.localNewClass
                                                              .classEndDate
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              972061999
                            ),
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.smAndDown
                ? _c("pb-class-editor", {
                    ref: "classEditor",
                    attrs: {
                      isModal: true,
                      input: _vm.localInput,
                      value: _vm.localIsEditingClass,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _c("pb-confirm", { ref: "classesConfirm" }),
      _c("manage-lessons", {
        attrs: {
          classId: _vm.manageClassId,
          startDate: _vm.manageStartDate,
          endDate: _vm.manageEndDate,
        },
        model: {
          value: _vm.showManageLessons,
          callback: function ($$v) {
            _vm.showManageLessons = $$v
          },
          expression: "showManageLessons",
        },
      }),
      _c(
        "pb-base-modal",
        {
          key: "reschedule-modal-" + _vm.localRefreshKey,
          attrs: {
            maxWidth: 600,
            expandable: false,
            withActions: false,
            withApply: false,
            onClose: _vm.doCloseRescheduleClass,
          },
          on: {
            apply: function ($event) {
              return _vm.applyEventDrop(_vm.eventDropInfo)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("rescheduleClassLabel"))),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.rescheduleClass,
            callback: function ($$v) {
              _vm.rescheduleClass = $$v
            },
            expression: "rescheduleClass",
          },
        },
        [
          _c(
            "validation-observer",
            { ref: "rescheduleObserver" },
            [
              _c(
                "v-card",
                [
                  !_vm.isDeletingSchedule && !_vm.isEventDrop
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-chip-group",
                            {
                              attrs: {
                                "active-class":
                                  "accent3--text font-weight-bold",
                                column: "",
                              },
                              model: {
                                value: _vm.noTimeSpecified,
                                callback: function ($$v) {
                                  _vm.noTimeSpecified = $$v
                                },
                                expression: "noTimeSpecified",
                              },
                            },
                            [
                              _c(
                                "v-chip",
                                { attrs: { filter: "", outlined: "" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("noTimeSpecifiedLabel"))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.noTimeSpecifiedTrue && !_vm.isDeletingSchedule
                    ? _c(
                        "v-card-text",
                        [
                          _c("pb-date-time-range-field", {
                            attrs: {
                              type: "time",
                              hideDetails: false,
                              start: _vm.rescheduleClassStartTime,
                              end: _vm.rescheduleClassEndTime,
                              rules: _vm.localRules,
                              min: _vm.startTime24HrFmt,
                              max: _vm.endTime24HrFmt,
                            },
                            on: {
                              "update:start": function ($event) {
                                _vm.rescheduleClassStartTime = $event
                              },
                              "update:end": function ($event) {
                                _vm.rescheduleClassEndTime = $event
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.localFoundInOtherDays
                    ? _c("v-card-text", [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$t("rescheduleClassSentence"))),
                        ]),
                        _c(
                          "div",
                          _vm._l(
                            _vm.daysToRescheduleClass,
                            function (days, index) {
                              return _c("span", { key: index }, [
                                _vm._v(_vm._s(days) + " "),
                                index + 1 < _vm.daysToRescheduleClass.length
                                  ? _c("span", [_vm._v(", ")])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm.isDeletingSchedule && !_vm.localFoundInOtherDays
                    ? _c("v-card-text", [
                        _c("div", [_vm._v(_vm._s(_vm.$t("deleteSlotLabel")))]),
                      ])
                    : _vm._e(),
                  _c("v-card-actions", { staticClass: "justify-center" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.localFoundInOtherDays,
                            expression: "localFoundInOtherDays",
                          },
                        ],
                        staticClass: "mb-6",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.applyToAllDays()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("applyToAllDaysLabel"))),
                            ]),
                          ]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.applyToOnlyThisDay()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("applyToOnlyThisDayLabel"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.localFoundInOtherDays,
                            expression: "!localFoundInOtherDays",
                          },
                        ],
                        staticClass: "mb-6",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.applyToOnlyThisDay()
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("applyLabel")))])]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          key: "schedule-modal-" + _vm.localRefreshKey,
          attrs: {
            maxWidth: 600,
            expandable: false,
            withActions: false,
            withApply: true,
            onClose: _vm.doCloseScheduleClass,
          },
          on: { apply: _vm.doApplyScheduleClass },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("scheduleClassLabel")))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.scheduleClassModal,
            callback: function ($$v) {
              _vm.scheduleClassModal = $$v
            },
            expression: "scheduleClassModal",
          },
        },
        [
          _c(
            "validation-observer",
            { ref: "applyScheduleObserver" },
            [
              _c(
                "v-card",
                [
                  _c("v-card-text", [
                    _c("span", { staticClass: "ml-4" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("selectDaysToScheduleLabel")) + " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-checkbox", {
                        staticClass: "ml-3",
                        attrs: { label: _vm.$t("classDaysLabel") },
                        on: {
                          click: function ($event) {
                            return _vm.toggleLocalDayTeachCheckboxes()
                          },
                        },
                        model: {
                          value: _vm.localDayTeachAll,
                          callback: function ($$v) {
                            _vm.localDayTeachAll = $$v
                          },
                          expression: "localDayTeachAll",
                        },
                      }),
                      _vm._l(_vm.cycleDays, function (day, index) {
                        return _c("v-checkbox", {
                          key: index,
                          staticClass: "ml-3",
                          attrs: { label: day.title },
                          on: {
                            click: function ($event) {
                              return _vm.toggleLocalDayTeachAllCheckbox()
                            },
                          },
                          model: {
                            value: _vm.localDayTeach[index],
                            callback: function ($$v) {
                              _vm.$set(_vm.localDayTeach, index, $$v)
                            },
                            expression: "localDayTeach[index]",
                          },
                        })
                      }),
                      _c(
                        "v-chip-group",
                        {
                          attrs: {
                            "active-class": "accent3--text font-weight-bold",
                            column: "",
                          },
                          model: {
                            value: _vm.noTimeSpecified,
                            callback: function ($$v) {
                              _vm.noTimeSpecified = $$v
                            },
                            expression: "noTimeSpecified",
                          },
                        },
                        [
                          _c(
                            "v-chip",
                            { attrs: { filter: "", outlined: "" } },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("noTimeSpecifiedLabel"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  !_vm.noTimeSpecifiedTrue
                    ? _c(
                        "v-card-text",
                        [
                          _c("pb-date-time-range-field", {
                            attrs: {
                              type: "time",
                              hideDetails: false,
                              start: _vm.rescheduleClassStartTime,
                              end: _vm.rescheduleClassEndTime,
                            },
                            on: {
                              "update:start": function ($event) {
                                _vm.rescheduleClassStartTime = $event
                              },
                              "update:end": function ($event) {
                                _vm.rescheduleClassEndTime = $event
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("class-alternate-day-editor", {
        attrs: {
          isModal: _vm.localIsModal,
          alternateDayTitle: _vm.localAlternateDayTitle,
          specialDayId: _vm.localSpecialDayId,
          specialDayDescription: _vm.localSpecialDayDescription,
          action: _vm.localSpecialDayAction,
        },
        on: {
          "hide-special-day-modal": function ($event) {
            return _vm.hideAlternateDay()
          },
        },
        model: {
          value: _vm.alternateDayModal,
          callback: function ($$v) {
            _vm.alternateDayModal = $$v
          },
          expression: "alternateDayModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }