









































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const classes = namespace('classes');
const settings = namespace('settings');

@Component
export default class ClassAlternateDayEditor extends Vue {
  localRefreshKey = CommonUtils.generateUUID();
  eventText = '';
  localAlternateDayTitle = '';

  localRules = [
    (v: any) => !!v || 'Required'
  ];

  @settings.Getter
  getUserMode!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @classes.Action
  updateSpecialDays!: (params?: any) => Promise<any>;

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({ type: String, default: '' })
  alternateDayTitle!: string;

  @Prop({ type: String, default: '' })
  specialDayDescription!: string;

  @Prop({ type: Number, default: -1 })
  specialDayId!: number;

  @Prop({ type: String })
  action!: string;

  @Watch('value')
  refreshTextEditor() {
    this.localAlternateDayTitle = '';
    this.eventText = '';
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  @Watch('alternateDayTitle')
  changeAlternateDayTitle() {
    this.localAlternateDayTitle = this.alternateDayTitle;
    this.eventText = this.specialDayDescription
  }

  get constructSpecialDayInformation() {
    var specialDayInformation:any = {};
    if (this.action !== 'D') {
      specialDayInformation.name = this.localAlternateDayTitle;
      specialDayInformation.description = this.eventText;
      specialDayInformation.teacherId = this.userInfo.userId;
      specialDayInformation.schoolId = this.schoolId;
      if (this.getUserMode === 'A') {
        specialDayInformation.specialDaySchoolId = this.schoolId;
      } else {
        specialDayInformation.specialDaySchoolId = 0
      }
      specialDayInformation.action = this.action;
      specialDayInformation.specialDayId = this.specialDayId;
      specialDayInformation.yearId = this.userInfo.schoolYearSettings.currentYearId;
      specialDayInformation.noScheduleChange = 'T'
    } else {
      specialDayInformation.teacherId = this.userInfo.userId;
      specialDayInformation.schoolId = this.schoolId;
      specialDayInformation.action = this.action;
      specialDayInformation.specialDayId = this.specialDayId;
      specialDayInformation.yearId = this.userInfo.schoolYearSettings.currentYearId;
    }
    return specialDayInformation
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  doApply() {
    CommonUtils.showLoading();
    this.updateSpecialDays(this.constructSpecialDayInformation).then((resp:any) => {
      this.$emit('hide-special-day-modal');
    });
  }
}
